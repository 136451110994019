import { withAnalytics } from 'react-analytics-collector'

const NON_SESSION_PAGES = {
  ACCOUNT_TRANSFER_APPROVAL: 'account.transfer.approval'
}

export const SCREENS = {
  DASHBOARD: 'dashboard',
  USER_PROFILE: 'user-profile',
  USER_PROFILE_SECURITY: 'user-profile.security',
  USERS_LIST_ACCOUNTS: 'users.accounts',
  USERS_LIST_PROFILE: 'users.profile',
  USERS_LIST_GROUPS: 'users.groups',
  GROUP_MEMBERS: 'groups.members',
  SUBSCRIPTIONS_LIST: 'subscriptions.list',
  SUBSCRIPTIONS_CHECKOUT: 'subscriptions.checkout',
  SUBSCRIPTIONS_CHANGE_PLAN: 'subscriptions.change-plan',
  SUBSCRIPTIONS_PAYMENT: 'subscriptions.payment',
  SUBSCRIPTIONS_UPDATE_PAYMENT: 'subscriptions.update-payment',
  SUBSCRIPTIONS_INVOICES: 'subscriptions.invoice',
  SUBSCRIPTIONS_ADDRESSES: 'subscriptions.address',
  SECURITY_AGENTS_HOME: 'security.agents.landing',
  SECURITY_AGENTS_DEFAULT: 'security.agents.default',
  SECURITY_AGENTS_CUSTOM_POLICIES: 'security.agents.custom-policies',
  SECURITY_AGENTS_CUSTOM_POLICIES_ADD: 'security.agents.custom-policies.add',
  SECURITY_AGENTS_CUSTOM_POLICIES_EDIT: 'security.agents.custom-policies.edit',
  SECURITY_AGENTS_SESSION_MANAGEMENT: 'security.agents.session-management',
  SECURITY_AGENTS_FIELD_PERMISSIONS: 'security.agents.field-permissions',
  SECURITY_CONTACTS: 'security.contacts.landing',
  SECURITY_CONTACTS_CUSTOM_POLICIES: 'security.contacts.custom-policies',
  SECURITY_CONTACTS_CUSTOM_POLICIES_ADD: 'security.contacts.custom-policies.add',
  SECURITY_CONTACTS_CUSTOM_POLICIES_EDIT: 'security.contacts.custom-policies.edit',
  ORGANIZATION: 'organization',
  AUDIT_LOGS: 'audit-logs',
  LOGIN_CONSENT: 'login.consent',
  ...NON_SESSION_PAGES
}

const routeToNameMap = {
  '/': SCREENS.DASHBOARD,
  '/users-groups/users': SCREENS.USERS_LIST_ACCOUNTS,
  '/users-groups/groups': SCREENS.GROUP_MEMBERS,
  '/subscriptions': SCREENS.SUBSCRIPTIONS_LIST,
  '/subscriptions/invoices': SCREENS.SUBSCRIPTIONS_INVOICES,
  '/subscriptions/addresses': SCREENS.SUBSCRIPTIONS_ADDRESSES,
  '/security': SCREENS.SECURITY_AGENTS_HOME,
  '/security/agents': SCREENS.SECURITY_AGENTS_HOME,
  '/security/agents/default': SCREENS.SECURITY_AGENTS_DEFAULT,
  '/security/agents/custom-policies': SCREENS.SECURITY_AGENTS_CUSTOM_POLICIES,
  '/security/agents/custom-policies/:id/edit': SCREENS.SECURITY_AGENTS_CUSTOM_POLICIES_ADD,
  '/security/agents/session-management': SCREENS.SECURITY_AGENTS_SESSION_MANAGEMENT,
  '/security/contacts': SCREENS.SECURITY_CONTACTS,
  '/security/contacts/custom-policies': SCREENS.SECURITY_CONTACTS_CUSTOM_POLICIES,
  '/security/contacts/custom-policies/:id/edit': SCREENS.SECURITY_CONTACTS_CUSTOM_POLICIES_ADD,
  '/organization': SCREENS.ORGANIZATION,
  '/audit-logs': SCREENS.AUDIT_LOGS,
  '/login-consent': SCREENS.LOGIN_CONSENT
}

const routeToNameRegexMap = {
  '/users-groups/users/\\d+/accounts': SCREENS.USERS_LIST_ACCOUNTS,
  '/users-groups/users/\\d+/profile': SCREENS.USERS_LIST_PROFILE,
  '/users-groups/users/\\d+/usergroups': SCREENS.USERS_LIST_GROUPS,
  '/users-groups/groups/\\d+/members': SCREENS.GROUP_MEMBERS,
  '/subscriptions/\\d+/checkout/payment': SCREENS.SUBSCRIPTIONS_PAYMENT,
  '/subscriptions/\\d+/updatepayment': SCREENS.SUBSCRIPTIONS_UPDATE_PAYMENT,
  '/subscriptions/\\d+/checkout': SCREENS.SUBSCRIPTIONS_CHECKOUT,
  '/security/agents/custom-policies/\\d+/edit': SCREENS.SECURITY_AGENTS_CUSTOM_POLICIES_EDIT,
  '/security/contacts/custom-policies/\\d+/edit': SCREENS.SECURITY_CONTACTS_CUSTOM_POLICIES_EDIT
}

export const getAnalyticNameByPathName = (history) => {
  const {
    location: { pathname }
  } = history
  if (routeToNameMap[pathname]) {
    return routeToNameMap[pathname]
  }
  for (let routeRegx in routeToNameRegexMap) {
    if (new RegExp(routeRegx).test(pathname)) {
      return routeToNameRegexMap[routeRegx]
    }
  }
  return pathname
}

export const getAnalyticsWrapper = (name, Component, props) => {
  return withAnalytics(name, Component, props)
}
