import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

import UserSession from '../UserSession'
import { getQueryParamsWithRedirectUri, analyticsUtils } from '../../utils'
import RBACProvider from '../RBACProvider'

const GET_ACCESS_ROUTE = '/login'

// a route to which the user can be redirected if
// they do no have admin privileges
const DEFAULT_ALLOWED_ROUTE = '/'

const IfUserSessionExists = (props) => {
  const { children, analyticsIdentity, searchParam, ...rest } = props
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <React.Fragment>
          <UserSession.IfUserSessionExists>
            {analyticsUtils.getAnalyticsWrapper(
              analyticsUtils.getAnalyticNameByPathName(rest) || analyticsIdentity,
              children,
              routeProps
            )}
          </UserSession.IfUserSessionExists>
          <UserSession.IfUserSessionAbsent>
            {() => {
              window.location.assign(
                GET_ACCESS_ROUTE + '?' + (searchParam || getQueryParamsWithRedirectUri(routeProps))
              )
            }}
          </UserSession.IfUserSessionAbsent>
        </React.Fragment>
      )}
    />
  )
}

const DefaultRouteIfUserSessionAbsent = ({ routeProps, searchParams }) => {
  return (
    <UserSession.IfUserSessionAbsent>
      {() => {
        window.location.assign(GET_ACCESS_ROUTE)
      }}
    </UserSession.IfUserSessionAbsent>
  )
}

const renderer = ({ routeProps, skipAnalytics, children, analyticsIdentity, rest }) => {
  if (skipAnalytics) {
    return <React.Fragment>{children}</React.Fragment>
  }
  return analyticsUtils.getAnalyticsWrapper(
    analyticsUtils.getAnalyticNameByPathName(rest) || analyticsIdentity,
    children,
    routeProps
  )
}

const IfAdmin = (props) => {
  const { children, analyticsIdentity, skipAnalytics, ...rest } = props
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return (
          <React.Fragment>
            <UserSession.IfUserSessionExists>
              <UserSession.IfAdmin>
                {renderer({ routeProps, children, analyticsIdentity, skipAnalytics, rest })}
              </UserSession.IfAdmin>
              <UserSession.IfNotAdmin>
                <Redirect to={{ pathname: DEFAULT_ALLOWED_ROUTE }} />
              </UserSession.IfNotAdmin>
            </UserSession.IfUserSessionExists>
            <DefaultRouteIfUserSessionAbsent routeProps={routeProps} />
          </React.Fragment>
        )
      }}
    />
  )
}

const IfPrivilegesExist = (props) => {
  const { privileges, children, analyticsIdentity, skipAnalytics, ...rest } = props
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return (
          <React.Fragment>
            <UserSession.IfUserSessionExists>
              <RBACProvider.IfPrivilegesExist privileges={privileges}>
                {renderer({ routeProps, children, analyticsIdentity, skipAnalytics, rest })}
              </RBACProvider.IfPrivilegesExist>
              <RBACProvider.IfPrivilegesAreAbsent privileges={privileges}>
                <Redirect to={{ pathname: DEFAULT_ALLOWED_ROUTE }} />
              </RBACProvider.IfPrivilegesAreAbsent>
            </UserSession.IfUserSessionExists>
            <DefaultRouteIfUserSessionAbsent routeProps={routeProps} />
          </React.Fragment>
        )
      }}
    />
  )
}

export default class RouteWithPriveleges extends Component {
  static LoggedIn = IfUserSessionExists
  static IfAdmin = IfAdmin
  static IfPrivilegesExist = IfPrivilegesExist
  render() {
    return <Route {...this.props} />
  }
}
