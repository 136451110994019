import React, { useContext } from 'react'
import delve from 'dlv'

import { AppStateContext, USER_SESSION_DOES_NOT_EXIST } from '../AppDataProvider'
import { getDataFromAppStateContext } from '../AppDataProvider/AppStateContext'

const IfUserSessionExists = ({ children }) => {
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  if (delve(getCurrentUserDetails, 'data.user.id')) {
    return typeof children === 'function'
      ? children(delve(getCurrentUserDetails, 'data.user'))
      : children
  } else {
    return null
  }
}

const IfUserSessionAbsent = ({ children }) => {
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  if (
    (getCurrentUserDetails && getCurrentUserDetails === USER_SESSION_DOES_NOT_EXIST) ||
    !!getCurrentUserDetails.error
  ) {
    if (typeof children === 'function') {
      children()
    } else {
      return children
    }
  } else {
    return null
  }
}

const IfAdmin = ({ children }) => {
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  if (delve(getCurrentUserDetails, 'data.user.admin')) {
    return children
  } else {
    return null
  }
}

const IfNotAdmin = ({ children }) => {
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  if (!delve(getCurrentUserDetails, 'data.user.admin')) {
    return children
  } else {
    return null
  }
}

const UserSession = ({ children }) => {
  const appState = useContext(AppStateContext)
  const getCurrentUserDetails = getDataFromAppStateContext(appState, 'getCurrentUserDetails', {})
  if (delve(getCurrentUserDetails, 'data.user.id')) {
    return children(getCurrentUserDetails.data)
  } else {
    return null
  }
}
UserSession.IfUserSessionExists = IfUserSessionExists
UserSession.IfUserSessionAbsent = IfUserSessionAbsent
UserSession.IfAdmin = IfAdmin
UserSession.IfNotAdmin = IfNotAdmin

export default UserSession
